import './Reviews.scss'
import React, {useState} from "react";
import img from '../../img/reviews/modal-img.png'
import {useMutation} from "@apollo/client";
import {ADD_REVIEW} from "../../apollo/Mutations";
import {Link, useNavigate, useParams} from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import {useTranslation} from "react-i18next";

function ReviewModal({clientName, id}) {

    const {t : translate} = useTranslation();
    const translateFormPath = 'leftReviewsPage.reviewForm'
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [containsLink, setContainsLink] = useState(false);
    const [loading, setLoading] = useState(false);
    const {companyId} = useParams();
    const navigate = useNavigate();


    const handleClose = () => {
        setShowSuccessModal(false);
        navigate(`/company/${companyId}`);
    };

    const [leftReview] = useMutation(ADD_REVIEW, {
        onCompleted: () => {
            setLoading(false);
            setShowSuccessModal(true);
        },
        onError: () => {
            setLoading(false);
            navigate(`/company/${companyId}/reviews/${id}/review-is-left`);

        }
    })

    const inputHandler = (event) => {
        const name = event.target.name;
        let value = event.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setContainsLink(false);
        if (name === "userComment") {
            if (value.length >= 200) {
                value = value.slice(0, 200);
            }
        }

    }
    const submitHandler = (event) => {
        event.preventDefault();
        const regex = /(http[s]?:\/\/[^\s]+)/g;
        const hasLink = regex.test(formData.userComment);
        setContainsLink(hasLink);
        if (!hasLink) {
            setLoading(true);
            leftReview({
                variables: {
                    reviewInput: {
                        rating: parseInt(formData.rating),
                        name: formData.name ? `${formData.name}` : `${clientName}`,
                        text : formData.userComment ? `${formData.userComment}` : null
                    },
                    uuid: id,
                }
            });
        }
    }

    return (<>
            <>
                {!loading ?
                <>
                    <form className='col-lg-6 mb-4 mb-lg-0' id={"ratingForm"} onSubmit={submitHandler}>
                        <div className='bg-white h-100 rounded p-4 '>
                            <h5 className='text-center mb-4'>
                                {translate(`${translateFormPath}.review`)}
                            </h5>
                            <div className="full-stars mb-4">
                                <div className="rating-group">
                                    <input name="rating" id="rating-5" onChange={inputHandler} value="5" type="radio"
                                           required/>
                                    <label htmlFor="rating-5">
                                        <svg className='img-fluid' width="63" height="60" viewBox="0 0 63 60"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.0171 58.8405C12.5178 59.6102 10.8201 58.2651 11.1219 56.5467L14.3412 38.2199L0.675005 25.2129C-0.602169 23.9973 0.0589595 21.7748 1.76994 21.532L20.7753 18.8355L29.2494 2.07018C30.0128 0.559939 32.0806 0.559939 32.844 2.07018L41.318 18.8355L60.3234 21.532C62.0344 21.7748 62.6955 23.9973 61.4184 25.2129L47.7522 38.2199L50.9714 56.5467C51.2733 58.2651 49.5756 59.6102 48.0763 58.8405L31.0467 50.0987L14.0171 58.8405Z"/>
                                        </svg>
                                    </label>
                                    <input name="rating" id="rating-4" value="4" onChange={inputHandler} type="radio"
                                           required/>
                                    <label htmlFor="rating-4">
                                        <svg className='img-fluid' width="63" height="60" viewBox="0 0 63 60"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.0171 58.8405C12.5178 59.6102 10.8201 58.2651 11.1219 56.5467L14.3412 38.2199L0.675005 25.2129C-0.602169 23.9973 0.0589595 21.7748 1.76994 21.532L20.7753 18.8355L29.2494 2.07018C30.0128 0.559939 32.0806 0.559939 32.844 2.07018L41.318 18.8355L60.3234 21.532C62.0344 21.7748 62.6955 23.9973 61.4184 25.2129L47.7522 38.2199L50.9714 56.5467C51.2733 58.2651 49.5756 59.6102 48.0763 58.8405L31.0467 50.0987L14.0171 58.8405Z"/>
                                        </svg>
                                    </label>
                                    <input name="rating" id="rating-3" value="3" onChange={inputHandler} type="radio"
                                           required/>
                                    <label htmlFor="rating-3">
                                        <svg className='img-fluid' width="63" height="60" viewBox="0 0 63 60"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.0171 58.8405C12.5178 59.6102 10.8201 58.2651 11.1219 56.5467L14.3412 38.2199L0.675005 25.2129C-0.602169 23.9973 0.0589595 21.7748 1.76994 21.532L20.7753 18.8355L29.2494 2.07018C30.0128 0.559939 32.0806 0.559939 32.844 2.07018L41.318 18.8355L60.3234 21.532C62.0344 21.7748 62.6955 23.9973 61.4184 25.2129L47.7522 38.2199L50.9714 56.5467C51.2733 58.2651 49.5756 59.6102 48.0763 58.8405L31.0467 50.0987L14.0171 58.8405Z"/>
                                        </svg>
                                    </label>
                                    <input name="rating" id="rating-2" value="2" onChange={inputHandler} type="radio"
                                           required/>
                                    <label htmlFor="rating-2">
                                        <svg className='img-fluid' width="63" height="60" viewBox="0 0 63 60"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.0171 58.8405C12.5178 59.6102 10.8201 58.2651 11.1219 56.5467L14.3412 38.2199L0.675005 25.2129C-0.602169 23.9973 0.0589595 21.7748 1.76994 21.532L20.7753 18.8355L29.2494 2.07018C30.0128 0.559939 32.0806 0.559939 32.844 2.07018L41.318 18.8355L60.3234 21.532C62.0344 21.7748 62.6955 23.9973 61.4184 25.2129L47.7522 38.2199L50.9714 56.5467C51.2733 58.2651 49.5756 59.6102 48.0763 58.8405L31.0467 50.0987L14.0171 58.8405Z"/>
                                        </svg>
                                    </label>
                                    <input name="rating" id="rating-1" value="1" onChange={inputHandler} type="radio"
                                           required/>
                                    <label htmlFor="rating-1">
                                        <svg className='img-fluid' width="63" height="60" viewBox="0 0 63 60"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.0171 58.8405C12.5178 59.6102 10.8201 58.2651 11.1219 56.5467L14.3412 38.2199L0.675005 25.2129C-0.602169 23.9973 0.0589595 21.7748 1.76994 21.532L20.7753 18.8355L29.2494 2.07018C30.0128 0.559939 32.0806 0.559939 32.844 2.07018L41.318 18.8355L60.3234 21.532C62.0344 21.7748 62.6955 23.9973 61.4184 25.2129L47.7522 38.2199L50.9714 56.5467C51.2733 58.2651 49.5756 59.6102 48.0763 58.8405L31.0467 50.0987L14.0171 58.8405Z"/>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    onChange={inputHandler}
                                    type="name" className="form-control rounded-1" name={"clientName"} id="floatingInput"
                                    defaultValue={clientName}
                                    placeholder={translate('reservationPage.reservationForm.userName')} required></input>
                                <label
                                    htmlFor="floatingInput" className='text-info'>
                                    {translate('reservationPage.reservationForm.userName')}
                                    <span
                                    className='text-danger'>*</span></label>
                            </div>
                            <div className="form-floating mb-5">
                              <textarea
                                  onChange={inputHandler}
                                  name={"userComment"}
                                  maxLength={200}
                                  className={`form-control comment rounded-1 ${containsLink ? 'is-invalid' : ''}`}
                                  placeholder={translate('reservationPage.reservationForm.userComment')}
                                  id="floatingTextarea"></textarea>

                                <label htmlFor="floatingTextarea" className='text-info'>
                                    {translate(`${translateFormPath}.review`)}
                                </label>
                                {containsLink && (
                                    <div className="invalid-feedback">
                                        {translate(`${translateFormPath}.noLinkInComment`)}.
                                    </div>
                                )}
                                <p className={"mb-0 fs-7 text-secondary mt-2"}>{translate(`${translateFormPath}.calcSymbol`)} {formData.userComment ? formData.userComment.length : 0} {translate(`${translateFormPath}.from`)} 200</p>
                            </div>
                            <button className='w-100 btn btn-orange fw-bold text-white rounded-1'>
                                {translate(`${translateFormPath}.addReview`)}
                            </button>
                        </div>
                    </form>
                    {showSuccessModal && (
                        <div className="modal ">
                            <div className="modal-dialog ">
                                <div className="modal-content rounded-1 px-3 pb-3">
                                    <div className="modal-header pe-0 border-0">
                                        <button className='btn-close' onClick={handleClose}></button>
                                    </div>
                                    <div className='modal-body text-center px-5 mb-5'>
                                        <img className='mb-5 img-fluid' loading='lazy' src={img} alt='Review Success'/>
                                        <h4 className='fw-normal'>
                                            {translate(`${translateFormPath}.tyForReview`)}!
                                        </h4>
                                    </div>
                                    <Link to={`/company/${companyId}`} className='btn btn-primary w-100'>
                                        {translate('toMainPage')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </> :
                <Spinner/>}
            </>

        </>
    )
}

export default ReviewModal